import "./intro-form.style.scss";
import FormInput from "../form-input/form-input.component";
import { Fragment, useEffect, useState } from "react";
import { submitInfoAPI } from "../../apis/submit-info";
import { eventDetailsAPI } from "../../apis/event-details";

const defaultFormFields = {
  fullName: "",
  email: "",
};

const IntroForm = ({ id }) => {
  const [newFormFields, setNewFormFields] = useState(defaultFormFields);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const { fullName, email } = newFormFields;

  useEffect(() => {
    const fetchEventDetails = async () => {
      const data = await eventDetailsAPI(id);

      if (data) {
        setImageUrl(data.payLoad.event.imageUrl);
      }
    };
    fetchEventDetails();
  }, [id]);

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setNewFormFields({ ...newFormFields, [name]: value });
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(email);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      if (!validateEmail(email)) {
        throw new Error("Invalid email");
      }

      const data = await submitInfoAPI(
        { email: email, fullName: fullName },
        id
      );

      if (data.statusCode === 200) {
        event.target.reset();
        window.location.href = "home";
      }
    } catch (err) {
      setErrorMessage(err.message);
      console.error(err.message);
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };

  return (
    <div className="sign-in-form-container">
      <img src={imageUrl} alt="event" className="logo-app" />

      <form key={errorMessage} onSubmit={submitHandler}>
        <FormInput
          label="Full Name"
          type="text"
          placeholder="Full Name"
          name="fullName"
          value={fullName}
          onChange={onChangeHandler}
          required
        />
        <FormInput
          label="Email"
          type="email"
          placeholder="email-address"
          name="email"
          value={email}
          onChange={onChangeHandler}
          required
        />

        <Fragment>
          <label className="form-control">
            I agree to{" "}
            <a href="https://mytallyapp.com/Terms-and-Conditions.pdf">
              terms and conditions
            </a>
            <input type="checkbox" className="checkbox" required />
          </label>
        </Fragment>

        <span className="error-message-container">{errorMessage}</span>
        <button className="sign-in-button">Submit</button>
      </form>
    </div>
  );
};

export default IntroForm;
