import axios from "axios";

export const eventDetailsAPI = async (id) => {
  try {
    const response = await axios.get(
      `https://prodapi.babytuesday.link/events/openEventDetails/${id}`
    );
    if (!response) throw new Error("Invalid id");

    const data = await response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
};
