import { useParams } from "react-router-dom";
import IntroForm from "../../components/intro-form/intro-form.component";
import "./intro-route.style.scss";

const IntroRoute = () => {
  const { id } = useParams();
  return <IntroForm id={id} />;
};

export default IntroRoute;
