import "./code-scan.route.style.scss";
import { ReactComponent as Logo } from "../../assets/Frame 2.svg";

const CodeScan = () => {
  return (
    <div className="code-container">
      <Logo className="logo-app"></Logo>
      <h1 className="code-scan-heading"> Please scan the QR code</h1>
    </div>
  );
};

export default CodeScan;
