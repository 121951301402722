import { Route, Routes } from "react-router-dom";
import IntroRoute from "./routes/introduction/intro-route";
import HomePage from "./routes/home/home-route";
import CodeScan from "./routes/Code-scan/code-scan-route";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<CodeScan />}></Route>
      <Route path="/:id" element={<IntroRoute />}></Route>
      <Route path="home" element={<HomePage />}></Route>
    </Routes>
  );
};

export default App;
