// "https://api.babytuesday.link/api/"
import axios from "axios";

export const submitInfoAPI = async (responseUser, id) => {
  const responseObject = { ...responseUser, eventId: id };
  try {
    const response = await axios.post(
      `https://prodapi.babytuesday.link/dataCapture`,
      responseObject
    );

    const data = await response.data;

    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const errorMessage = err.response.data.message;
      throw new Error(errorMessage);
    } else {
      throw new Error("Unexpected error occurred");
    }
  }
};
