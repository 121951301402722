import "./home-route.style.scss";
import party from "party-js";
import { useEffect } from "react";

const HomePage = () => {
  useEffect(() => {
    party.confetti(document.querySelector(".welcome"), {
      count: party.variation.range(50, 80),
      angle: party.variation.range(70, 110),
      spread: party.variation.range(90, 120),
      origin: { y: 0.6 },
      colors: [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FFEB3B",
        "#FFC107",
        "#FF9800",
        "#FF5722",
        "#795548",
      ],
    });
  }, []);

  return (
    <div className="welcome">
      <img
        className="event-picture"
        src="https://tuesday-tally.s3.amazonaws.com/web/images/tally_logo.png"
        alt="eventPicture"
      ></img>
      <h1 className="event-heading">
        Thank You for submitting the Information. Enjoy your event 🎊 🥳
      </h1>
    </div>
  );
};

export default HomePage;
